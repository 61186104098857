import React from "react";
import { Container, Row, Col } from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
        <footer className="footer">
          <Container>
            <Row className="row-grid align-items-center mb-5">
              <Col lg="6">
                <h3 className="text-primary font-weight-light mb-2">
                  Thank you for supporting us!
                </h3>
                <h4 className="mb-0 font-weight-light">

                </h4>
              </Col>
            </Row>
            <hr />
            <Row className="align-items-center justify-content-md-between">
              <Col md="6">
                <div className="copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                      href="https://cs.wcupa.edu/schen/"
                      target="_blank"
                      rel="Dr. Chen"
                  >
                    Dr. Chen
                  </a>
                  .
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
    );
  }
}

export default SimpleFooter;
