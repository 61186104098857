import React from 'react';
import { Card, Col, Row, Button, Space, Image, Divider, message } from 'antd';
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import { Container } from "reactstrap";
import { MailOutlined, DiscordOutlined } from '@ant-design/icons'; // 引入邮件和消息图标

const copyToClipboard = (text, messagePrefix) => {
    navigator.clipboard.writeText(text).then(() => {
        // 显示消息提示，这里使用messagePrefix来自定义消息内容
        message.success(`${messagePrefix} copied to clipboard`);
    }).catch(err => {
        // 错误处理
        message.error(`Failed to copy ${messagePrefix}`);
    });
};

class Profile extends React.Component {
    state = {
        users: [
            {
                imageSrc: "1.jpg",
                name: "Chaughn Robin",
                description: "Hi, my name is Chaughn Robin, and I am a Dutch-American CS Student really passionate about coding in Java and C++, documentation, and data science and management. I am a senior and I spend most of my free time working on a game in Java using the LibGDX framework.",
                email: "cr1001404@wcupa.edu",
                username: "bxdda",
                status: "offline"
            },
            {
                imageSrc: "IMG_6955.png",
                name: "Madison Megivern",
                description: "I am a junior CS student with a minor in mathematics. I am most knowledgeable in Java and Python but have experience with MATLAB, C, C#, Windows PowerShell, and CI/CD Pipelines. I’m passionate about AI and Machine Learning.",
                email: "mm1001981@wcupa.edu",
                username: "maddymegs",
                status: "offline"
            },
            {
                imageSrc: "kyle2-headshot.jpg",
                name: "Kyle Brennan",
                description: "Hey, y'all! My name is Kyle, I'm completing my final year of my B.S. in Computer Science with a minor in Applied Statistics, and I have a strong passion for several data science-related fields, including machine learning, natural language processing, and statistical analysis.  Some of the programming languages I know include Java, Python, and R, as well as some experience with SQL, C/C++, and Julia. I am currently involved with research involving the two-way machine translation of American Sign Language (ASL) and English for use in education.",
                email: "kb1031865@wcupa.edu",
                username: "Jyle",
                status: "offline"
            },

            {
                imageSrc: "EP head photo.png",
                name: "Elias Platt",
                description: "I am a graduate CS student, and I completed my bachelor's degree in CS at Ithaca College in 2022. I enjoy programming of all sorts and I am looking to expand my general knowledge and experience here at WCU.",
                email: "ep1035303@wcupa.edu",
                username: "grendal",
                status: "offline"
            },{
                imageSrc: "Folsom_profilepicture.jpg",
                name: "Olivia Folsom",
                description: "Hi, I am a senior majoring in CS and German; I am passionate about learning and have a current interest in cloud computing. I’m dedicated to becoming a stronger programmer, with most knowledge in Java and Python.",
                email: "of994933@wcupa.edu",
                username: "folo9",
                status: "offline"
            },
        ],
    };



    componentDidMount() {
        this.pollUserStatus();
        this.interval = setInterval(() => this.pollUserStatus(), 10000); // 每10秒轮询一次
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    pollUserStatus = () => {
        const statusPromises = this.state.users.map(user =>
            fetch(`https://discord.wcpc.fun/user-status/${user.username}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // Check if data is not null and has a status property
                    if (data && 'status' in data) {
                        return data;
                    } else {
                        // Return a default object to avoid null reference errors
                        return { status: 'offline' }; // or any default value you see fit
                    }
                })
                .catch(error => {
                    console.error('Error fetching status:', error);
                    // Return a default object on error to avoid null reference errors
                    return { status: 'offline' }; // Adjust the default status as needed
                })
        );

        Promise.all(statusPromises).then(results => {
            this.setState(prevState => {
                const updatedUsers = prevState.users.map((user, index) => {
                    const data = results[index];
                    // No need to check data for null here as we're handling it above
                    return { ...user, status: data.status };
                });
                return { users: updatedUsers };
            });
        });
    };



    renderProfileCard(imageSrc, profileName, description, email, discord, status) {

        // Correctly defining statusIndicatorStyle as a function
        const statusIndicatorStyle = (status) => ({
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            backgroundColor: status === 'online' ? 'green' :
                status === 'idle' ? 'yellow' :
                    status === 'do not disturb' ? 'red' :
                        status === 'offline' ? 'lightgrey' : 'red',
            display: 'inline-block',
            marginRight: '5px',
            verticalAlign: 'middle',
        });



        // 在用户名旁边添加状态指示器
        const profileTitle = (
            <span>
<span style={statusIndicatorStyle(status)} />
                {profileName}
        </span>
        );

        // 动态样式，适用于整个卡片
        const cardStyle = {
            width: '100%',
            margin: '5px 0',
        };

        // 卡片封面图片
        const cardImage = (
            <div className="card-profile-image" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <Image
                    width={200}
                    src={imageSrc}
                    style={{ borderRadius: '50%', border: '5px solid white' }}
                    preview={false} // 禁用点击预览功能

                />
            </div>
        );

        // 自我简介部分的样式，设置固定高度并允许滚动
        const descriptionStyle = {
            height: '110px', // 自我简介部分的固定高度
            overflowY: 'auto', // 超出时显示滚动条
            overflowX: 'hidden',
        };

        // 电子邮件和Discord操作
        const cardActions = [
            <Button
                type="link"
                onClick={() => copyToClipboard(email, 'Email address')}
                icon={<MailOutlined />}
                key="email"
                style={{ color: '#1890ff' }}
            >
                Email
            </Button>,
            <Button
                key="discord"
                type="link"
                onClick={() => copyToClipboard(discord, 'Discord username')}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <span style={statusIndicatorStyle(status)} />
                <DiscordOutlined style={{ marginRight: '5px' }} />
                {discord} {/* 这里直接显示discord变量的值，即用户的Discord用户名 */}
            </Button>,
        ];

        return (
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Card
                    hoverable
                    style={cardStyle}
                    cover={cardImage}
                    actions={cardActions}
                >
                    <Card.Meta
                        title={profileName}
                        description={<div style={descriptionStyle}>{description}</div>}
                    />
                </Card>
            </Col>
        );
    }

    render() {
        return (
            <>
                <main ref="main">
                    <div className="position-relative">
                        {/* shape Hero */}
                        <section className="section section-lg section-shaped pb-250">
                            <div className="shape shape-style-1 shape-default">
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                            </div>
                <Container className="py-lg-md d-flex">
                    <div className="col px-0">
                        <Row>
                            <Col lg="6">
                                <h1 className="display-3 text-white">
                                    CSC Introductory Course Support
                                </h1>
                                <p className="lead text-white">
                                    CSC student peer tutors offer drop-in, in-person, and virtual support for our major introductory courses, 16+ hours per week. No appointment is necessary.
                                </p>
                                <p className="text-white">
                                    Location: UNA139 and Discord <br />
                                    Courses Supported: CSC141, CSC142, CSC240, CSC241 <br />
                                    Please email CSC Assistant Chair, Dr. Si Chen, for more information.
                                </p>
                                <div className="btn-wrapper">
                                    <Button
                                        className="btn-icon mb-3 mb-sm-0"
                                        color="info"
                                        href="mailto:schen@wcupa.edu"
                                    >
                <span className="btn-inner--icon mr-1">
                    <i className="ni ni-email-83" />
                </span>
                                        <span className="btn-inner--text">Email Dr. Si Chen</span>
                                    </Button>
                                    <Button
                                        className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                        color="default"
                                        href="https://discord.gg/AYA2knSMyB"
                                        target={"_blank"}
                                    >

                                        <span className="btn-inner--text">

                    <DiscordOutlined style={{ marginRight: '5px' }} /> Join us on Discord
                </span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                            {/* SVG separator */}
                            <div className="separator separator-bottom separator-skew">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    preserveAspectRatio="none"
                                    version="1.1"
                                    viewBox="0 0 2560 100"
                                    x="0"
                                    y="0"
                                >
                                    <polygon
                                        className="fill-white"
                                        points="2560 0 2560 100 0 100"
                                    />
                                </svg>
                            </div>

                        </section>

                        <Space direction="vertical" size="middle" style={{ display: 'flex', width: '100%' }}>

                            </Space>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                    <iframe
                        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vS6U2oMX4SDRQvsalxWni3DWDJOXfgLx7EnER40mWbtY_vNRa3OKZmpZ5bDixC6nrJCPKeH2etGHBYQ/pubhtml?gid=0&single=true&widget=true&headers=false"
                        style={{ width: '70%', height: '800px', border: '0', marginBottom: '20px' }} // 适当调整宽度以匹配卡片宽度
                        title="Google Spreadsheet"
                    ></iframe>
                        </div>
                        <div>
                    <Container>
                        <Divider orientation="left" style={{ fontSize: '24px' }}>Meet Our Team</Divider>
                        <Space direction="vertical" size="middle" style={{ display: 'flex', width: '100%' }}>
                            <Row gutter={16} justify="center">
                                {this.state.users.map(user =>
                                    this.renderProfileCard(
                                        require(`../assets/img/theme/${user.imageSrc}`),
                                        user.name,
                                        user.description,
                                        user.email,
                                        user.username,
                                        user.status
                                    )
                                )}
                            </Row>
                        </Space>
                        {/*<Space direction="vertical" size="middle" style={{ display: 'flex', width: '100%' }}>*/}
                        {/*    <Row gutter={16} justify="center">*/}
                        {/*        {this.renderProfileCard(require("../assets/img/theme/65bae7e93d3fd_download.jpg"), "Mahmoud Gudarzi", "Computer Science graduate student. Analytical and creative computer science professional, dedicated to continuous learning, collaboration, and sharing knowledge. My expertise encompasses programming and a diverse educational foundation in Computer Science and STEM.","mg920115@wcupa.edu","moodersgooders")}*/}
                        {/*        {this.renderProfileCard(require("../assets/img/theme/headshott.jpg"), "James May", "My names is James May and I am a master’s student majoring in computer science, I completed my bachelor’s degree in Computer Science here at West Chester in 2023 and have interests in AI, Computer Vision, and Data Science.","jm1006779@wcupa.edu","sogeking6458")}*/}
                        {/*        {this.renderProfileCard(require("../assets/img/theme/1.jpg"), "Chaughn Robin", "Hi, my name is Chaughn Robin, and I am a Dutch-American CS Student really passionate about coding in Java and C++, documentation, and data science and management. I am a senior and I spend most of my free time working on a game in Java using the LibGDX framework.","cr1001404@wcupa.edu","bxdda")}*/}
                        {/*        {this.renderProfileCard(require("../assets/img/theme/Kareem Shahatta.jpg"), "Kareem Shahatta", "I am a senior student at WCU who transferred from DCCC and have a lot of experience with Java. I also recently started advancing and increasing my knowledge about Python.","ks1000662@wcupa.edu","bluepharaoh")}*/}
                        {/*    </Row>*/}
                        {/*</Space>*/}
                    </Container>

                </div>

                    </div>
                </main>
                <SimpleFooter />
            </>
        );
    }
}

export default Profile;
